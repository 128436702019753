<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'dhs'
          }"
          :to="{ name: 'listadoDhs' }"
        >
          Dhs
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoMarcasDhs' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'marcas'
          }"
        >
          Marcas
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoModelosDhs' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'modelos'
          }"
        >
          Modelos
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoTiposDhs' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'tipos'
          }"
        >
          Tipos
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CmpSecciones',
  props: ['seccion']
}
</script>

<style scoped>
.no-cursor{
  cursor: default;
}
</style>