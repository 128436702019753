<template>
  <Layout :tituloPagina="`Network | Dispositivos para hogar | Marcas`">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="marcas"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-10 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarMarcas()
          "
          :datoini="dato"
          placeholder="Dato de la marca"
        ></filtrador>
      </div>
      <div class="col-sm-2 col-3 text-right">
        <router-link :to="{ name: 'nuevaMarcaDhs' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva marca
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nMarcasEnPag}} marcas en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nMarcasTotales}} marcas en total
        </span>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap" id="tabla-marcas-dhs">
            <thead>
              <tr>
                <th style="width: 50px">Foto</th>
                <th>Nombre</th>
                <th style=" width: 150px">Modulos</th>
                <th style=" width: 180px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="marca in marcas" :key="marca.id">
                <td>
                  <img
                    :src="
                      API + '/dhs/marcas/' + marca.id +
                      '/imagen?tipo_foto=64x64&_tk=' + tk +
                      '&_tiempo=' + new Date().getTime()
                    "
                    v-show="marca.id_foto != null"
                    class="iconmarca"
                  />
                </td>
                <td>
                  {{ marca.nombre }}
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'listadoModelosDhs',
                      query: { id_marca: marca.id }
                    }"
                    class="btn btn-outline-success btn-sm"
                  >
                    Modelos
                    <i class="mdi mdi-chevron-triple-right"></i>
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionMarcaDhs',
                      params: { id: marca.id }
                    }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminarMarca(marca)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Borrar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarMarcas()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from '../CmpSecciones.vue'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
import API from '@/API.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoMarcasDhs',
  components: { Layout, CmpSecciones, Paginador, Filtrador },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.location.origin, 
      marcas: [],
      marca: {},
      dato: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoMarcasDhs.por_pagina') || 10),
      ultima_pagina: 0,
      nMarcasEnPag:0,
      nMarcasTotales:0
    }
  },

  created: function() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.por_pagina = parseInt(this.$route.query.por_pagina)

    //Cargas iniciales
    self.refrescarMarcas()
  },

  methods: {
    refrescarMarcas: function() {
      var self = this

      iu.spinner.mostrar('#tabla-marcas-dhs')

      this.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.por_pagina
        }
      }).catch(error => {})

      var parametros = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.por_pagina
      }

      MarcaDhsSrv.marcasJSON(parametros).then(response => {
        localStorage.setItem('argusblack.listadoMarcasDhs.por_pagina', self.por_pagina)
        let res = response.data
        self.marcas = res.marcas
        self.pagina = res.pagina
        self.ultima_pagina = res.ultima_pagina
        self.nMarcasEnPag = res.nMarcasEnPag
        self.nMarcasTotales = res.total
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-marcas-dhs')
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarMarcas()
    },

    preguntaEliminarMarca: function(marca) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Eliminación de marca de dispositivos de hogar",
        html: '¿Está seguro que desea eliminar la marca <strong>'+marca.nombre+'</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          MarcaDhsSrv.eliminar(marca.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarMarcas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar la marca del dispositivo de hogar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-marcas-dhs')
  }
}
</script>

<style scoped>
.iconmarca {
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>